@mixin bg-linear(){
  background-image: linear-gradient(180deg, #f3f8ff 0%, #0044ad 100%);
  border-radius: 50rem;
}
.wrapper{
  position: relative;
  overflow: hidden;
}
.border_box{
  border: 1px solid #0044ad;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  
}
.text-title{
  color:#00379e;
}
.linear-box{
  position: absolute;
  transform: rotate(64deg);
  right: -10%;
  top: -20%;
  @include media-breakpoint-down(md) {
      right: -250px;
      top: -180px;
  }
}
.weblinear-box{
  position: absolute;
  transform: rotate(45deg);
  // right: -10%;
  bottom: -145px;
  left: -70px;
  .webbg-linear-1{
    @include bg-linear();
    width: 110px;
    height: 12vw;
    margin-right: 70px;
  }
  .webbg-linear-2.d-none.d-md-block{
    @include bg-linear();
    width: 270px;
    height: 35vw;  
  }    

}

.btn-secondary {
  color: #fff;
}
.bg-linear-1{
  @include bg-linear();
  width: 345px;
  height: 50vw;
  margin-right: 70px;
  @include media-breakpoint-down(md) {
    width: 302px;
    height: 502px;
    opacity: .35;
}
}

.bg-linear-2{
  @include bg-linear();
  width: 136px;
  height: 35vw;
}

@include media-breakpoint-down(md) {
  .title-group{
    text-align: center;
  }
  h2.title{
    font-size: 1.5rem;
  }
  .text-dl{
    font-size: 1.75rem;
  }
}
// btn
.btn{
  border-radius: 50rem;
}
.btn-dl{
  width: 200px;
  @include media-breakpoint-down(md) {
    width: 100%;
}
}
.btn-group-dl{
  display: flex;
  margin-top: 30px;
}
.testpic {
  max-width: 290px;
  height: auto;
}
.qr{
  width: 130px;
  height: auto;
}
.btn:hover{
  background-color: #0053d4;
  border-color: #0053d4;
}
.title{
  letter-spacing: 1px;
  font-size: 66px;
}
.text-dl{
  letter-spacing: 0.8px;
  font-size: 25px;
  line-height: 1.8;
  font-weight: 500;
}

@media screen and (min-width: 767px){
  .mobiledownload{
      display: none;
    }
}
@media screen and (max-width: 767px){
  .webdownload{
    display: none;
  }
}
@media screen and (max-width: 425px){
  .gtalkpic{
    max-height: 250px;
  }
  .title{
    font-size: 45px;  
  }
  .text-dl{
    font-size: 22px;  
    line-height: 1.2;
  }
}
@media screen and (max-width: 320px){
  .gtalkpic{
    max-height: 200px;
  }
  .title{
    font-size: 65px;  
  }
  .text-dl{
    font-size: 22px;  
    line-height: 0.8;
  }
}